<template>
  <v-card>
    <form @submit.prevent.stop="submitForm">
      <v-card-title class="text-h5 font-weight-regular primary lighten-5">
        New Configuration
      </v-card-title>
      <v-divider />
      <v-card-text class="py-3 px-5">
        <v-text-field
          v-model="application"
          outlined
          disabled
          hide-details
          label="Application"
          autocomplete="off"
          required
          class="my-3"
        />
        <v-text-field
          v-model="name"
          outlined
          hide-details
          label="Property name"
          autocomplete="off"
          required
          class="my-3"
        />
        <v-textarea
          v-model="value"
          rows="2"
          outlined
          hide-details
          label="Property value"
          autocomplete="off"
          required
          class="my-3"
        />
        <v-text-field
          v-model="profile"
          outlined
          hide-details
          label="Property profile"
          autocomplete="off"
          required
          class="my-3"
        />
        <v-text-field
          v-model="label"
          outlined
          hide-details
          label="Property label"
          autocomplete="off"
          required
          class="my-3"
        />
      </v-card-text>
      <v-divider />
      <v-card-actions class="lighten-4 py-3 px-5">
        <v-spacer />
        <v-btn class="px-5" color="primary" type="submit" :loading="loading">Submit</v-btn>
      </v-card-actions>
    </form>
  </v-card>
</template>

<script>
export default {
  props: {
    application: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    name: null,
    value: null,
    profile: "jdbc",
    label: "latest",
    loading: false,
  }),
  methods: {
    async submitForm() {
      try {
        this.loading = true;
        const configUrl = [
          "",
          "api",
          "config",
          "create",
          this.application,
          this.profile,
          this.label,
          this.name,
        ].join("/");
        const response = await this.$axios.post(configUrl, this.value);
        if (response.data.code !== 0) {
          throw new Error(response.data.message);
        }
        this.$emit("change");
      } catch (err) {
        this.$iziToast.showError(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
